import React, { Fragment } from 'react';
import Headroom from 'react-headroom';
import { Flex, Image } from 'rebass/styled-components';
import styled from 'styled-components';
import Logo from './Logo/imaginatio.png';
import PropTypes from 'prop-types';
import { Link } from "gatsby";
import { AiOutlineYoutube } from 'react-icons/ai';
import Hidden from "@material-ui/core/Hidden";
import { useAlert } from "react-alert";
import {BiMailSend} from 'react-icons/bi'
import {HiOutlineClipboardCopy} from 'react-icons/hi'
import ReactTooltip from 'react-tooltip';

const HeaderContainer = styled(Headroom)`  
  * {
    transition: ${(props) => props.fixed && 'background-color 0.1s ease'};
  }

  .headroom--pinned {
    background-color: ${(props) => props.theme.colors.backgroundLanding};
  } 
  padding-right: 2em;
  padding-left: 2em;
  opacity: ${(props) => props.fixed ? 1 : 0.9};
  width: 100%;
  position: ${(props) => props.fixed ? '' : 'absolute'};
  background-color: ${(props) => props.fixed ? props.theme.colors.backgroundLanding : 'transparent'};
  z-index: 100;
`;



const copy = async () => {
  await navigator.clipboard.writeText('proyectos@imaginatio.cl');
  
};

const Button = styled.button`
  background-color: transparent;
  cursor: pointer;
  border-color: transparent;
`;




const Header = ({ fixed = false }) => {
  const alert = useAlert();
  return (
    <HeaderContainer fixed={fixed}>
      <Flex
        flexWrap="wrap"
        justifyContent="space-between;"
        alignItems="center"
        p={3}
      >
        <Fragment>
          <Hidden smDown>

        <Flex>
        <Link
            to="/">
            <Image
              src={Logo}
              width="160px"
              alt="Imaginatio"
              style={{
                cursor: 'pointer',
              }}
            />
          </Link>
        </Flex>
        </Hidden>

          <Flex alignItems="center">
            <div style={{marginRight: '1em'}}>
              <Button
              data-tip="Copiar correo: proyectos@imaginatio.cl"
              onClick={
                ()=>{copy(); alert.success("Correo Copiado!");}} >
                    <HiOutlineClipboardCopy 
                    color='white' 
                    style={{fontSize: '45px'}}/>
              </Button>
              <ReactTooltip place='bottom' type='dark' effect='float'/>
            </div>
            
            <div style={{marginRight: '1em'}}>
              <Button
              data-tip="Enviar mensaje"
              onClick={() => window.location = 'mailto:proyectos@imaginatio.cl'}>
                    <BiMailSend 
                    color='white' 
                    style={{fontSize: '50px'}}/>
              </Button>
              <ReactTooltip place='bottom' type='dark' effect='float'/>

            </div>

            <div >
            <Button 
              href ="https://www.youtube.com/channel/UCNuvPqZY25Cc9RtEiqoggRA?sub_confirmation=1"
              target="blank"
              >
                <AiOutlineYoutube  
                color='white'
                style={{fontSize: '55px'}}/>
            </Button>
            </div>
            
          </Flex>
        </Fragment>
      </Flex>
    </HeaderContainer >
  );
}

Header.propTypes = {
  fixed: PropTypes.bool
};

export default Header;
