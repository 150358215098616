import React from 'react';
import styled from 'styled-components';
// import Logo from '../../media/icon.webp';

const SpinnerContainer = styled.div`
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #000;
    position: absolute;
    z-index: 100;
    top:0;
    left: 0;
`;


//const Spinner = styled.img`
//    height: 110px;
//    width: 110px;
//`;

const Loader = () => {
    return (
        <SpinnerContainer>
        </SpinnerContainer>
    );
};

export default Loader;
