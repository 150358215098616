import React from "react"
import Section from "../components/Section"
import Grid from "@material-ui/core/Grid"
import Hidden from "@material-ui/core/Hidden"
import colors from "../../colors"


const Background = () => (
  <div
    style={{
      backgroundColor: colors.backgroundLanding,
      zIndex: -1,
      position: "absolute",
      height: "100%",
      width: "100%",
    }}
  ></div>
)

const workProcessDesk = require("../../media/images/wpC2-min.png").default
const workProcessMov = require("../../media/images/wpM-min.png").default

const Enterprises = () => (
  <Section.Container id="clientes" Background={Background}>
    <Section.Header name="Nuestro Proceso" color={colors.textLight} />
    <Section.subHeader name="Nuestro Proceso se Inspira en el Desarrollo Colaborativo" color={colors.textBajada} />
    <Hidden smDown>
      <Grid
        container
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          addingInline:"5%",
          marginTop: "3%"
        }}
      >
        <img
          alt={"Proceso de Trabajo"}
          key={"desk"}
          src={workProcessDesk}
          width="80%"
        />
      </Grid>
    </Hidden>
    <Hidden mdUp>
      <Grid
        container
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <img
          alt={"Proceso de Trabajo"}
          key={"desk"}
          src={workProcessMov}
          style={{ width: '90%' }}
        />
      </Grid>
    </Hidden>
  </Section.Container>
)

export default Enterprises
