import React from 'react';
import Section from '../components/Section';
import Grid from '@material-ui/core/Grid';
import Slider from '../components/Slider';
import styled from "styled-components"
import Hidden from "@material-ui/core/Hidden"
import colors from "../../colors"


const Background = () => (
    <div
      style={{
        backgroundColor: colors.background,
        zIndex: -1,
        position: "absolute",
        height: "100%",
        width: "100%",
        }}
    ></div>
  )

const ServicesCard = styled.div`
    position: relative;
    display: flex;
    height: 110%;
    width: 98%;
    justify-content: center;
    align-items:center;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.7);
    transition: all 0.25s;
    top: 0;
    border-radius: 10px;
    text-align: center;

    /* &:hover {
        top: -15px;
    }

    &:hover > .icon-container {
        background-color: ${props => props.theme.colors.grey};
    }

    &:hover > .icon {
        color: #fff;
    } */
`


const wordsFromList = [
    {
        id: 1,
        message: '“Como Municipio de la Unión, hemos tenido la oportunidad de trabajar con la empresa Imaginatio, donde fuimos testigos de su compromiso y disposición de concretar un trabajo intachable, una empresa con fuerte sentido de responsabilidad y un equipo empático en el trato con los vecinos”” ',
        enterprisePersonName: 'María Jesús Millar',
        enterprisePersonTitle: 'Encargada Of. Seguridad Pública 2019-2021',
        enterpriseName: 'I. Municipalidad La Unión',
        img: require('../../media/testimonios/seguridadpublica.webp').default
    },
    {
        id: 2,
        message: '“La gestión realizada en nuestros proyectos para la seguridad del barrio, fue de excelente calidad, una gran empatía con los vecinos y paciencia para responder cuantas veces fuera necesario nuestras dudas para elegir lo mejor en seguridad. Apreciaciones de dirigentes y vecinos del Consejo Vecinal de Desarrollo Barrio Aberto Daiber.” ',
        enterprisePersonName: 'Juan Carlos Vera',
        enterprisePersonTitle: 'Presidente',
        enterpriseName: 'CVD Barrio Alberto Daiber',
        img: require('../../media/testimonios/cvd.webp').default
    },
    {
        id: 3,
        message: '“El trabajo realizado en nuestra comunidad fue excelente por el compromiso, cumplimiento con las fechas establecidas y asesoramiento a todos los favorecidos en la instalación de las alarmas comunitarias, quienes quedaron muy conformes con sus equipos.” ',
        enterprisePersonName: ' Luis Muñoz Salazar',
        enterprisePersonTitle: 'Pdte. Comité de Adelanto',
        enterpriseName: 'Parcelación las Vertientes',
        img: require('../../media/testimonios/traiguen.webp').default
    },
    {
        id: 4,
        message: '“Nosotros trabajamos con la empresa Imaginatio en el proyecto alarmas comunitarias. Para nosotros fueron un gran aporte, ya que como comunidad rural no es tan accesible llegar en cada momento, pero ellos tienen una disposición increíble. Los vecinos y nosotros como directiva estamos muy conformes, ya que fue muy buena experiencia trabajar con ellos.” ',
        enterprisePersonName: 'María Eugenia Jaramillo F.',
        enterprisePersonTitle: 'Pdta. Junta de Vecinos',
        enterpriseName: 'Rapaco Panguimilla',
        img: require('../../media/testimonios/rapaco.webp').default
    }
];

const logosList = [
    {
        id: 1,
        alt: 'colun',
        img: require('../../media/logos/colun.webp').default, height: 60
    },{
        id: 2,
        alt: 'Salfa',
        img: require('../../media/logos/Inia.webp').default, height: 60
    },{
        id: 3,
        alt: 'Municipalidad LA Unión',
        img: require('../../media/logos/muniunion.webp').default, height: 65
    },{
        id: 4,
        alt: 'UACh',
        img: require('../../media/logos/uach.webp').default, height: 70
    },{
        id: 5,
        alt: 'INIA',
        img: require('../../media/logos/salfa.webp').default, height: 40
    },{
        id: 6,
        alt: 'Alerce',
        img: require('../../media/logos/fundacion.webp').default, height: 60
    },{
        id: 7,
        alt: 'Leufulab',
        img: require('../../media/logos/leufulab.webp').default, height: 70
    },{
        id: 8,
        alt: 'CDN-del-Ranco',
        img: require('../../media/logos/cdn-ranco.png').default, height: 70
    },{
        id: 9,
        alt: 'WudMedia',
        img: require('../../media/logos/wudmedia.png').default, height: 20
    },{
        id: 10,
        alt: 'Truferos',
        img: require('../../media/logos/truferos.webp').default, height: 60
    },{
        id: 11,
        alt: 'Vetservi',
        img: require('../../media/logos/vetservi.webp').default, height: 45
    },{
        id: 12,
        alt: 'Okeanos',
        img: require('../../media/logos/okeanos.webp').default, height: 60
    },{
        id: 13,
        alt: 'Vertical',
        img: require('../../media/logos/vertical.webp').default, height: 75
    },{
        id: 14,
        alt: 'Ecozero',
        img: require('../../media/logos/ecozero.webp').default, height: 50
    },{
        id: 15,
        alt: 'Verberna',
        img: require('../../media/logos/verbena.webp').default, height: 30
    },{
        id: 16,
        alt: 'tankka',
        img: require('../../media/logos/tankka.webp').default, height: 60
    },{
        id: 17,
        alt: 'FAM',
        img: require('../../media/logos/fam.webp').default, height: 70
    },
];

const WordsFrom = () => (
    <Section.Container id="words" Background={Background}>
        <Section.Header name="Han confiado en Nosotros" />
        <Grid container justify="center" style={{paddingInline:"5%"}}>
            <Grid item
            xs={12}
            sm={6}
            >
                <ServicesCard>
                <div className='container'>
                <Hidden smDown>

                <div
                className="row"
                >
                <iframe width="95%" height="320" src="https://www.youtube.com/embed/NffzAv_SRLs" title="Experiencia Imaginatio 2022" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                </div>
                </Hidden>

                <Hidden mdUp>
                <div
                className="row"
                >
                <iframe width="95%" src="https://www.youtube.com/embed/NffzAv_SRLs" title="Experiencia Imaginatio" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                
                </div>
                </Hidden>

                <Hidden smDown>
                <div
                className="row"
                >
                <iframe width="31%"  src="https://www.youtube.com/embed/ALxP0f09ZFo" title="Experiencia Imaginatio R" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                <iframe width="31%"  src="https://www.youtube.com/embed/HLLmmzTCJYY" title="Experiencia Imaginatio T" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                <iframe width="31%"  src="https://www.youtube.com/embed/Rl63Rb1vuLg" title="Experiencia Imaginatio P" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                </div>
                </Hidden>

                </div>

                </ServicesCard>
            </Grid>
            <Hidden mdUp>
            <Grid style={{
                marginTop: "15%"
            }}>
                <span>&nbsp;</span>
            </Grid>
            </Hidden>
            <Grid item
            xs={12}
            sm={6}
            >
                <ServicesCard>
                <Slider wordsFromList={wordsFromList} />
                </ServicesCard>
            </Grid>
            <Hidden mdUp>
            <Grid style={{
                marginTop: "20%"
            }}>
                <span>&nbsp;</span>
            </Grid>
            </Hidden>
            <Grid item
                xs={12}
                container
                style={{
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "6%",
                }}
                >
                {logosList.map((ent, i) => (
                    <img
                    alt={ent.alt}
                    key={ent.id}
                    src={ent.img}
                    style={{ height: ent.height, margin: 20 }}
                    />
                ))}
            </Grid>
            
        </Grid>
        
        
        
    </Section.Container >
);

export default WordsFrom;