import React from "react"
import Section from "../components/Section"
import { Heading } from "rebass/styled-components"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Grid from "@material-ui/core/Grid"
import styled from "styled-components"
import colors from "../../colors"


const Background = () => (
  <div
    style={{
      backgroundColor: colors.background,
      zIndex: -1,
      position: "absolute",
      height: "100%",
      width: "100%",
      }}
  ></div>
)

const ServicesCard = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    height: 110%;
    width: 100%;
    margin-right: 5%;
    justify-content: top;
    align-items:center;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.7);
    transition: all 0.25s;
    top: 0;
    border-radius: 10px;
    text-align: center;

    /* &:hover {
        top: -15px;
    }

    &:hover > .icon-container {
        background-color: ${props => props.theme.colors.primaryLight};
    }

    &:hover > .icon {
        color: #fff;
    } */
`

const Title = styled.p`
  font-size: 18px;
  color: ${props => props.theme.colors.text};
  margin-top: 25px;
  font-weight: bold;
`

const Description = styled.p`
  font-size: 16px;
  color: ${props => props.theme.colors.text};
  text-align: justify;
  margin-top: -3px;
  margin-left: 5%;
  margin-right: 5%;
  line-height: 1.6;
`


const serviciosub = {
  name: "Imaginatĭo se preocupa del desarrollo por medio de la tecnología, brindando las herramientas necesarias para que nuestros clientes logren su objetivo a corto, mediano o largo plazo. Buscamos un trabajo estrecho y personalizado."
}

const servicesList = [
  {
    id: 1,
    title: "Tecnologías y Transformación Digital",
    description:
      "Asesoramos a nuestros clientes en la incorporación de cultura digital entregando herramientas que les permitan obtener mejoras sostenibles, optimizar tiempos y recursos a su empresa u organización.",
    icon: "laptop-code",
  },
  {
    id: 2,
    title: "Gestión I+D+i",
    description:
      "Apoyamos y asesoramos a nuestros clientes y colaboradores en la gestión de sus carteras de proyectos I+D+i. Trabajamos con ellos desde la idea hasta la implementación y seguimiento de sus proyectos.",
    icon: "project-diagram",
  },
  {
    id: 3,
    title: "Software y Hardware",
    description:
      "Creamos dispositivos y programas a medida, que vienen a dar una  solución tecnológica a la problemática de nuestros clientes. Analizamos, diseñamos y desarrollamos.",
    icon: "robot",
  },
]

const Services = () => (
  <Section.Container id="servicios" Background={Background} >
    <Section.Header name="Nuestros Servicios" />
    <Heading textAlign="center" justify="center" marginBottom="1em" marginTop="-1em" marginX="4%"><Title >{serviciosub.name}</Title> </Heading>
    <Grid container justify="center"  style={{paddingInline:"3%"}}>
      {servicesList.map((service, i) => (
        <Grid
          item
          xs={12}
          sm={4}
          key={service.id}
          style={{
            display: "flex",
            marginTop: 25,
            marginBottom: 25,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ServicesCard>
            <FontAwesomeIcon
              className={"icon"}
              icon={service.icon}
              style={{ width: "20%", height: "20%", color: "#00008b", marginTop: '25px' }}
              
            />
            <Title>{service.title}</Title>
            <Description>{service.description}</Description>
          </ServicesCard>
        </Grid>
      ))}
    </Grid>
  </Section.Container>
)

export default Services
