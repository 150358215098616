import React from "react"
import styled from "styled-components"
import Grid from "@material-ui/core/Grid"
import { Image } from "rebass/styled-components"
import Logo from "./Logo/imaginatio.png"
import Hidden from "@material-ui/core/Hidden"
import { BsTelegram } from 'react-icons/bs';
import {AiOutlineMail, AiOutlineWhatsApp} from 'react-icons/ai';


const MainContainer = styled.div`
  flex-grow: 1;
`

const ContentContainer = styled.div`
  padding-left: 15%;
  display: flex;
  vertical-align: middle;
  justify-content: center;
  flex-direction: column;
  margin-left: 10%;
  color: ${props => props.theme.colors.primary};
`

const FooterContainer = styled.div`
  width: 100%;
  overflow: hidden;
  background-color: ${props => props.theme.colors.primaryDark};
  flex-direction: column;
  display: flex;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 10px;
`


//Footer Data
const proySocioCulturales = {
  value: "+56 9 90784744",
}
const proyDesarrolloDigit = {
  value: "+56 9 98974808",
}

const contacto = {
  name: "Contacto",
  value: "proyectos@imaginatio.cl",
}



export default function CenteredGrid() {
  return (
    <MainContainer>
      <FooterContainer>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} md={4} container justify="center"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}>
            <Image src={Logo} width="65%" alt="Logo" />
          </Grid>
          <Hidden smDown>
            <Grid item xs={12} md={4}>
              
            </Grid>
          </Hidden>
          
          
          <Grid item xs={12} md={4} >
          <ContentContainer>
              <span style={{display: 'flex', lineHeight: '30px'}}>
                    <AiOutlineMail color='#707273' style={{fontSize: '30px'}}/>
                    &nbsp;&nbsp;
                    {contacto.value}
                    
              </span>
              
              <span style={{display: 'flex', lineHeight: '30px', marginBottom: '3%', marginTop: '3%'}}>
                <AiOutlineWhatsApp
                color='#707273'
                style={{fontSize: '30px'}}
                />
                &nbsp;&nbsp;
                {proySocioCulturales.value}</span>
              
              <span style={{display: 'flex', lineHeight: '30px'}}>
                <BsTelegram
                color='#707273'
                style={{fontSize: '30px'}}
                />
                &nbsp;&nbsp;
                {proyDesarrolloDigit.value}</span>
              
          </ContentContainer>

          </Grid>
        </Grid>

        <Grid container justify="center" style={{marginTop: 40, color: 'white', fontWeight: '100'}}>
          <b>{`© Copyright`} {(new Date().getFullYear())} {`Imaginatio. All Rights Reserved.`}</b>
        </Grid>
      </FooterContainer>
    </MainContainer>
  )
}
