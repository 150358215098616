import React, { Component, Fragment } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import styled from "styled-components"

const SlideContainer = styled.div`
  background-color: transparent;
  width: 70%;
  margin-left: 15%;
`

const SlideInnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
`

const ImageContainer = styled.div`
  height: 140px;
  width: 140px;
  border-radius: 50%;
  overflow: hidden;
  border: 5px solid;
  border-color: ${props => props.theme.colors.text};
`

const Title = styled.h4`
  font-size: 18px;
  color: ${props => props.theme.colors.text};
  font-weight: bold;
`

const SubTitle = styled.p`
  font-size: 17px;
  line-height: 30px;
  color: ${props => props.theme.colors.text};
  font-weight: bold;
`

const Description = styled.p`
  font-size: 19px;
  line-height: 30px;
  font-weight: 500;
  margin: 14px 0 40px 0 !important;
  color: ${props => props.theme.colors.text};
`



class Slider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentSlide: 1,
    }
  }

  componentDidMount(){
    this.timer = setInterval(() => {      
      this.goToNext(1);
    }, 11000);
  }

  componentWillUnmount() {
    clearInterval(this.timer);  
  }

  render() {
    return (
      <div>
        <Fragment>
          {this.props.wordsFromList.map((words, i) => (
            <SlideContainer
              key={i}
              hidden={this.state.currentSlide !== words.id}
            >
              <SlideInnerContainer>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <FontAwesomeIcon
                    onClick={e => this.goToNext(-1)}
                    icon={"arrow-circle-left"}
                    style={{
                      width: 45,
                      height: 45,
                      marginRight: 50,
                      color: "#fff",
                      cursor: "pointer",
                    }}
                  />
                  <ImageContainer>
                    <img
                      alt={words.enterpriseName}
                      src={words.img}
                      style={{ height: "100%", width:'100%', objectFit: "cover" }}
                    />
                  </ImageContainer>
                  <FontAwesomeIcon
                    onClick={e => this.goToNext(1)}
                    icon={"arrow-circle-right"}
                    style={{
                      width: 45,
                      height: 45,
                      marginLeft: 50,
                      color: "#fff",
                      cursor: "pointer",
                    }}
                  />
                </div>
                <Title>{words.enterprisePersonName}</Title>
                <SubTitle
                  style={{ marginTop: -15 }}
                >{`${words.enterprisePersonTitle}, ${words.enterpriseName}`}</SubTitle>
                <Description style={{ marginTop: 5 }}>
                  {words.message}
                </Description>
              </SlideInnerContainer>
            </SlideContainer>
          ))}
        </Fragment>
      </div>
    )
  }

  goToNext = num => {
    let len = this.props.wordsFromList.length
    let index =
      this.props.wordsFromList.findIndex(
        x => x.id === this.state.currentSlide
      ) + num
    if (index < 0) {
      index = len - 1
    } else if (index >= len) {
      index = 0
    }
    this.setState({
      currentSlide: this.props.wordsFromList[index].id,
    })
  }
}

export default Slider
