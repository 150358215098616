import React, { Component, Fragment } from 'react';

import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import 'react-awesome-button/dist/index';
import 'react-awesome-button/dist/themes/theme-rickiest.css';

import Loader from '../components/Loader';
import Layout from '../components/Layout';
import Header from '../components/Header';
import Landing from '../sections/Landing';
import Services from '../sections/Services';
import WorkProcess from '../sections/WorkProcess';
import WordsFrom from '../sections/WordsFrom';
import Footer from '../components/Footer';

import { positions, Provider } from "react-alert";
import AlertTemplate from "react-alert-template-basic";

library.add(fab, far, fas);

const options = {
  timeout: 3000,
  position: positions.TOP_CENTER
};



class IndexPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoadComplete: false
    }
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({
        isLoadComplete: true
      });
    }, 1);
  }

  render() {
    return (
      <Fragment>
        {this.state.isLoadComplete ?
          <Layout>
            <Provider template={AlertTemplate} {...options}>
            <Header />
            </Provider>
            <Landing />
            <Services />
            <WorkProcess />
            <WordsFrom />
            <Footer />
          </Layout >
          :
          <Loader />
        }
      </Fragment>
    );
  }
}

export default IndexPage;