import React, { Fragment } from "react"
import { Heading, Image } from "rebass/styled-components"
import { SectionLink } from "react-scroll-section"
import Section from "../components/Section"
import MouseIcon from "../components/MouseIcon"
import Particles from "react-particles-js"
import ImaginatioLogo from "../components/Logo/imaginatio.png"
import colors from "../../colors"
import Hidden from "@material-ui/core/Hidden"


const Background = () => {
  return (
    <Particles
      params={require("../../media/particlesjs-config.json")}
      style={{
        position: "absolute",
        backgroundColor: colors.backgroundLanding,
        height: "100vh",
        zIndex: -1,
      }}
    />
  )
}


const contacto = {
  name: "Transformando problemas en soluciones digitales"
}

const LandingPage = () => {
  return (
    <Section.Container id="home" Background={Background}>
      <Fragment>
        <Heading
          textAlign="center"
          as="h1"
          px={[0,3,7]}
          color="primary"
          fontSize={[4, 6]}
          mb={[3, 4, 5]}
          fontWeight="100"
        >
            <br></br><br></br>
            {contacto.name}
        </Heading>
        <SectionLink section="servicios">
          {({ onClick }) => <MouseIcon onClick={onClick} />}
        </SectionLink>
      </Fragment>
    </Section.Container>
  )
}

export default LandingPage
